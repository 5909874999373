/** @jsx jsx */
import { jsx, Heading, Text, Box } from "theme-ui"

import Layout from "../components/Layout"

const RefundsPage = () => {
  return (
    <Layout>
      <Box bg="#fff">
        <Box
          mx="auto"
          py={[60, 60, 100]}
          px={[20, 20, 40]}
          sx={{ maxWidth: 650 }}
        >
          <Heading sx={{ mb: 4 }}>Refunds policy</Heading>

          <Text variant="paragraph">
            Our policy lasts 14 days. If 14 days have gone by since your
            purchase, unfortunately, we can’t offer you a refund or exchange.
          </Text>

          <Text variant="paragraph">
            To be eligible for a return, your item must be unused and in the
            same condition that you received it. It must also be in the original
            packaging.
          </Text>

          <Text variant="paragraph">
            There are certain situations where only partial refunds are granted
            (if applicable)
          </Text>

          <Text variant="paragraph" as="ul">
            <Text variant="paragraph" as="li">
              Any item not in its original condition is damaged or missing parts
              for reasons not due to our error
            </Text>
            <Text variant="paragraph" as="li">
              Any item that is returned more than 30 days after delivery
            </Text>
          </Text>

          <Text variant="paragraph">
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item. We
            will also notify you of the approval or rejection of your refund.
          </Text>

          <Text variant="paragraph">
            If you are approved, then your refund will be processed, and a
            credit will automatically be applied to your credit card or original
            method of payment, within a certain amount of days.
          </Text>

          <Text variant="subtitle">Late or missing refunds</Text>
          <Text variant="paragraph">
            If you haven’t received a refund yet, first check your bank account
            again.
          </Text>

          <Text variant="paragraph">
            Then contact your credit card company, it may take some time before
            your refund is officially posted.
          </Text>

          <Text variant="paragraph">
            Next, contact your bank. There is often some processing time before
            a refund is posted.
          </Text>

          <Text variant="paragraph">
            If you’ve done all of this and you still have not received your
            refund yet, please contact us at{" "}
            <a href="mailto:help@adammonster.com">help@adammonster.com</a>.
          </Text>

          <Text variant="subtitle">Sale items</Text>
          <Text variant="paragraph">
            Only regular priced items may be refunded, unfortunately, sale items
            cannot be refunded.
          </Text>

          <Text variant="subtitle">Exchanges</Text>
          <Text variant="paragraph">
            We only replace items if they are defective or damaged. If you need
            to exchange it for a different size, send us an email at{" "}
            <a href="mailto:help@adammonster.com">help@adammonster.com</a> and
            send your item to 34 Belfast Street, Henley Beach SA 5022,
            Australia.
          </Text>

          <Text variant="subtitle">Gifts</Text>
          <Text variant="paragraph">
            If the item was marked as a gift when purchased and shipped directly
            to you, you’ll receive a gift credit for the value of your return.
            Once the returned item is received, a gift certificate will be
            mailed to you.
          </Text>

          <Text variant="paragraph">
            If the item wasn’t marked as a gift when purchased, or the gift
            giver had the order shipped to themselves to give to you later, we
            will send a refund to the gift giver and he will find out about your
            return.
          </Text>

          <Text variant="subtitle">Shipping</Text>
          <Text variant="paragraph">
            To return your product, you should mail your product to 34 Belfast
            Street, Henley Beach SA 5022, Australia
          </Text>

          <Text variant="paragraph">
            You will be responsible for paying for your own shipping costs for
            returning your item. Shipping costs are non-refundable. If you
            receive a refund, the cost of return shipping will be deducted from
            your refund.
          </Text>

          <Text variant="paragraph">
            Depending on where you live, the time it may take for your exchanged
            product to reach you may vary.
          </Text>

          <Text variant="paragraph">
            If you are shipping an item over $75, you should consider using a
            trackable shipping service or purchasing shipping insurance. We
            don’t guarantee that we will receive your returned item.
          </Text>
        </Box>
      </Box>
    </Layout>
  )
}

export default RefundsPage
